/* eslint-disable @typescript-eslint/no-var-requires */

import { getState } from "@sparky/framework";
import { SPARKY_NAV3_LEFT_NAV_EXTPOINT } from "@sparky/framework/extpoints";
import { MAIN_ROUTE_AI_FW, MAIN_ROUTE_AI_FW_V3 } from "../lib/constants";

// export const extensions: ExtensionsExports = ({ sparkyFramework, vars }) => {

export default {
    point: SPARKY_NAV3_LEFT_NAV_EXTPOINT,
    configs: [
        {
            id: "netsec.airuntime.nav.security.insights",
            config: {
                stateLoaders: ["auth", "airsNavState"],
                navigation: () => [
                    {
                        key: "insights",
                        path: "/insights",
                        title: "Insights",
                        children: [
                            {
                                key: "ai_security",
                                title: "AI Runtime Security",
                                path: MAIN_ROUTE_AI_FW_V3,
                                replacePaths: MAIN_ROUTE_AI_FW,
                                avail: () => {
                                    const state = getState()?.airsNavState;
                                    return state?.isAvailable();
                                },
                            },
                        ],
                    },
                ],
                isAllowed: () => true,
            },
        },
    ],
};